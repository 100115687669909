import React from "react";
import { Box, Container, Paper, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { Link, Outlet, useLocation } from "react-router-dom";
import { Capitalize, Pluralize } from "../functions/Formatters";
import { global } from "../context/global";

/**
 * Locally used component for tab panel.
 * @param {Object} props Props object
 * @param {Node} props.children Children of component
 * @param {Number} props.value Value of component
 * @param {Number} props.index Index of component
 * @returns
 */
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

/**
 * AppAdmin Component
 *
 * The `AppAdmin` component serves as the main layout for the admin section of the application.
 * It includes a tab navigation interface that allows users to switch between different admin pages,
 * such as Organisations, Themes, and Users. The selected tab is determined based on the current URL path.
 *
 * @component
 * @returns {JSX.Element} A component that displays admin tabs and renders the appropriate content based on the selected tab.
 */
export default function AppAdmin() {
    const location = useLocation();

    // Unnecessarly complex way of getting the current tab. Organisations has to match
    // either /admin/organisations or /admin. Themes has to match /admin/themes or /admin/themes/:id
    const childPages = [
        {
            value: 0,
            path: "/admin/organisations",
            label: Capitalize(Pluralize(global.organisation)),
            re: /(\/admin\/organisations)|(admin)$/,
        },
        {
            value: 1,
            path: "/admin/themes",
            label: Capitalize(Pluralize(global.theme)),
            re: /\/admin\/themes(\/\d)?/,
        },
        {
            value: 2,
            path: "/admin/users",
            label: Capitalize(Pluralize(global.user)),
            re: /\/admin\/users(\/\d)?/,
        },
        {
            value: 3,
            path: "/admin/enrollments",
            label: Capitalize(Pluralize(global.enrollment)),
            re: /\/admin\/enrollments(\/\d)?/,
        },
    ];
    const value = childPages.findIndex((page) => page.re.test(location.pathname));

    return (
        <Container component={Paper} maxWidth={'xl'}>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider", mt: 0 }}>
                    <Tabs value={value} aria-label="tabs_container">
                        {childPages.map((location) => (
                            <Tab
                                key={"tab_" + location.value}
                                id={`tab_link_to_${location.path}`}
                                label={location.label}
                                value={location.value}
                                component={Link}
                                to={location.path}
                            />
                        ))}
                    </Tabs>
                </Box>
                <Outlet />
            </Box>
        </Container>
    );
}
