import React, { useState } from "react";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
    Alert,
    Button,
    Container,
    Fab,
    Fade,
    Grid,
    IconButton,
    Paper,
    Slide,
    Stack,
    Typography,
} from "@mui/material";
import { setAuthTokens, setAccount } from "../store/slices/authSlice";
import ResetPasswordDialog from "../components/UserManagement/PasswordResetDialog";
import { fetchAllObjects } from "../store/storeFunc";
import { Cancel, CancelOutlined, CancelRounded, Close, PersonAdd, Restore } from "@mui/icons-material";
import EnrollRequestComponent from "../components/UserManagement/Enrollments.js/EnrollRequestComponent";
import { fetchEnrollments } from "../components/UserManagement/UserActions";
import { TextField } from "formik-mui";
/**
 * Login Component
 *
 * This React component represents the login page of the application. Users can enter their email
 * and password to authenticate and access the application. It includes form validation, error handling,
 * and a "Forgot Password" feature to reset passwords.
 *
 * @returns {JSX.Element} A component rendering the login form and handling user authentication.
 */
function Login() {
    const [message, setMessage] = useState(""); // State for displaying error or informational messages
    const [loading, setLoading] = useState(false); // State to track loading state
    const dispatch = useDispatch(); // Redux dispatch function
    const navigate = useNavigate(); // Routing function
    const [resetDialogOpen, setResetDialogOpen] = useState(false); // State for showing the password reset dialog

    const [showEnrollForm, setShowEnrollForm] = useState(false); // State for showing the user enrollment form
    const closeEnrollForm = () => setShowEnrollForm(false); // Function to close the user enrollment form
    const openEnrollForm = () => setShowEnrollForm(true); // Function to open the user enrollment form

    const account = useSelector((state) => state.auth.account); // Get the user account from Redux

    // Function to handle user login
    const handleLogin = (email, password) => {
        axios
            .post(`/auth/login/`, { email, password }) // Send a POST request to the login endpoint
            .then((res) => {
                if (res.data?.status === 401) {
                    pushErrorMessage(res);
                    return;
                }
                // Handle a successful login
                dispatch(
                    setAuthTokens({
                        token: res.data.access,
                        refreshToken: res.data.refresh,
                    })
                );
                dispatch(setAccount(res.data.user));
                if (res.data.user.is_staff) {
                    fetchEnrollments(dispatch, navigate);
                }
                fetchAllObjects(dispatch);
                setLoading(false);
                navigate("/");
            })
            .catch((error) => {
                pushErrorMessage(error.response);
            });
    };

    const pushErrorMessage = (response) => {
        if (!response) {
            // Handle network or server unreachable
            setMessage("De server is niet bereikbaar. Probeer het later opnieuw.");
        } else if (response?.data?.detail) {
            // Handle specific error message from the server
            setMessage(response.data.detail.toString());
        } else if (response?.status) {
            // Handle HTTP error status
            setMessage(`Er is iets misgegaan. Foutcode: ${response.status}.`);
        } else {
            // Handle unknown error
            setMessage("Onbekende fout. Probeer het later opnieuw.");
        }
        setLoading(false);
    };

    const handleReset = (setSubmitting) => {
        setMessage("");
        setSubmitting(false);
    };

    // Function to reset user's password
    const resetPassword = (email) => {
        axios
            .post(`dj-rest-auth/password/reset/`, { email: email }) // Send a POST request to reset the password
            .then((response) => {
                if (response.status === 200) {
                    setMessage(
                        "Als de gebruiker bestaat, wordt een email met reset instructies verzonden naar het ingevulde mailadres."
                    );
                }
            })
            .catch((error) => {
                setMessage(error.response.data.detail.toString());
            })
            .finally(() => {
                setResetDialogOpen(false);
            });
    };

    return (
        // <Stack spacing={5} justifyContent={"center"} alignItems={"center"}>
        <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
            <Grid item sm={12} md={6} xl={4} >  
                <Container maxWidth="sm" component={Paper}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Inloggen
                    </Typography>
                    <Stack spacing={2} sx={{ width: "100%" }}>
                        <Formik
                            initialValues={{
                                email: "",
                                password: "",
                            }}
                            onSubmit={(values) => {
                                setLoading(true);
                                handleLogin(values.email, values.password);
                            }}
                            validationSchema={Yup.object({
                                email: Yup.string().trim().required("Email is vereist"),
                                password: Yup.string().trim().required("Wachtwoord is vereist"),
                            })}
                        >
                            {({ values, isValid, setFieldValue, loading, submitting, setSubmitting }) => (
                                <Form >
                                    <ResetPasswordDialog
                                        open={resetDialogOpen}
                                        handleClose={() => setResetDialogOpen(false)}
                                        handleReset={resetPassword}
                                        email={values.email}
                                        setEmail={(email) => setFieldValue("email", email)}
                                    />{" "}
                                    {message && (
                                        <Alert
                                            severity="error"
                                            sx={{ width: "100%" }}
                                            action={
                                                <Button
                                                    color="inherit"
                                                    onClick={() => handleReset(setSubmitting)}
                                                >
                                                    <Close />
                                                </Button>
                                            }
                                        >
                                            {message}
                                        </Alert>
                                    )}
                                    <br />
                                    <Stack spacing={1}>
                                    <Field
                                        component={TextField}
                                        id="email"
                                        name="email"
                                        label="Email"
                                        type="email"
                                        autoComplete="email"
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ flex: 1 }}
                                    />
                                    <Field
                                        component={TextField}
                                        id="password"
                                        name="password"
                                        label="Wachtwoord"
                                        type="password"
                                        InputLabelProps={{ shrink: true }}
                                        autoComplete="current-password"
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={loading || !isValid || account !== null || submitting}
                                    >
                                        Log in
                                    </Button>

                                    </Stack>
                                </Form>
                            )}
                        </Formik>
                        <Button onClick={() => setResetDialogOpen(true)}>Wachtwoord vergeten</Button>
                    </Stack>
                </Container>
                <Fade
                    direction="up"
                    in={showEnrollForm}
                    mountOnEnter
                    unmountOnExit
                    timeout={{ enter: 800, exit: 0 }}
                >
                    <Container sx={{ mt: 2}} component={Paper}>
                        <Stack direction="row" justifyContent={"space-between"}>
                            <Typography variant="h5" component="h1" gutterBottom>
                                Registreren
                            </Typography>
                            <IconButton onClick={closeEnrollForm} sx={{ position: "relative", top: 0 }}>
                                <CancelRounded />
                            </IconButton>
                        </Stack>
                        <EnrollRequestComponent setShowForm={setShowEnrollForm} />
                    </Container>
                </Fade>

                <Slide
                    direction="up"
                    appear={showEnrollForm}
                    in={!showEnrollForm}
                    timeout={{ appear: 1000, enter: 1000, exit: 0 }}
                    sx={{ml: 'calc(50% - 68px)', mt: 2}}
                    
                >
                    <Fab
                        variant="extended"
                        color="primary"
                        aria-label="show_enroll_form"
                        onClick={() => openEnrollForm()}
                        
                    >
                        <PersonAdd sx={{ mr: 1 }} />
                        Registreren
                    </Fab>
                </Slide>
            </Grid>
            <Grid item sm={12} md={6} xl={8}>
                <Container maxWidth={'md'} disableGutters>
                <img src="infoGraphicLoginPage.jpg" alt="infoGraphicLoginPage" width={'100%'} />
                </Container>
            </Grid>
        </Grid>

        // </Stack>
    );
}

export default Login;
