import React from "react";

// Styling
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "@emotion/react";
import { Box, Container, CssBaseline, Paper } from "@mui/material";
import Theme from "./Theme";

// Routing
import AuthenticatedRoute from "./routes/AuthenticatedRoute";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// pages
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import ProfilePage from "./pages/Profilepage";
import ErrorPage from "./pages/ErrorPage";
import AppAdminPage from "./pages/AppAdminPage";
import OrgAdminPage from "./pages/OrgAdminPage";
import ThemePage from "./pages/ThemePage";
import ProjectPage from "./pages/ProjectPage";

// external packages
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import 'dayjs/locale/nl';

// store
import { store } from "./store/store";

//components
import Titlebar from "./components/Header";
import OrganisationOverview from "./components/OrganisationManagement/OrganisationOverview";
import ThemeOverview from "./components/ThemeManagement/ThemeOverview";
import ThemeDetails from "./components/ThemeManagement/ThemeDetails";
import AdminUsersOverview from "./components/UserManagement/AdminUsersOverview";
import Footer from "./components/Footer";
import Links from "./pages/Links";
import EnrollmentTable from "./components/UserManagement/Enrollments.js/EnrollmentTable";

function App() {
    return (
        <ThemeProvider theme={Theme}>
            <CssBaseline>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl">
                    <ToastContainer hideProgressBar={true} newestOnTop={true} position="bottom-right" />
                    <Provider store={store}>
                        <Router>
                            <Box sx={{ display: "flex", minHeight: "100vh", flexDirection: "column" }}>
                                <Box sx={{ flex: 1 }}>
                                    <Titlebar />
                                    <Routes>
                                        <Route
                                            index
                                            element={
                                                <AuthenticatedRoute>
                                                    <HomePage />
                                                </AuthenticatedRoute>
                                            }
                                        />
                                        <Route path="login" element={<LoginPage />} />
                                        <Route
                                            path="profile"
                                            element={
                                                <AuthenticatedRoute>
                                                    <ProfilePage />
                                                </AuthenticatedRoute>
                                            }
                                        />
                                        <Route
                                            path="admin"
                                            element={
                                                <AuthenticatedRoute>
                                                    <AppAdminPage />
                                                </AuthenticatedRoute>
                                            }
                                        >
                                            <Route index element={<OrganisationOverview />} />
                                            <Route path="organisations" element={<OrganisationOverview />} />
                                            <Route path="themes" element={<ThemePage />}>
                                                <Route index element={<ThemeOverview />} />
                                                <Route path=":themeID" element={<ThemeDetails />} />
                                            </Route>
                                            <Route path="users" element={<AdminUsersOverview />} />
                                            <Route path="enrollments" element={<EnrollmentTable />} />
                                        </Route>
                                        <Route
                                            path="/organisation"
                                            element={
                                                <AuthenticatedRoute>
                                                    <OrgAdminPage />
                                                </AuthenticatedRoute>
                                            }
                                        />
                                        <Route
                                            path="/links"
                                            element={
                                                <AuthenticatedRoute>
                                                    <Links />
                                                </AuthenticatedRoute>
                                            }
                                        />
                                        <Route
                                            path="/project"
                                            element={
                                                <AuthenticatedRoute>
                                                    <ProjectPage />
                                                </AuthenticatedRoute>
                                            }
                                        />
                                        <Route
                                            path="/themes"
                                            element={
                                                <AuthenticatedRoute>
                                                    <Container maxWidth={"xl"} component={Paper}>
                                                        <ThemePage />
                                                    </Container>
                                                </AuthenticatedRoute>
                                            }
                                        >
                                            <Route index element={<ThemeOverview />} />
                                            <Route path=":themeID" element={<ThemeDetails />} />
                                        </Route>
                                        <Route path="*" element={<ErrorPage />} />
                                    </Routes>
                                </Box>
                                <Footer />
                            </Box>
                        </Router>
                    </Provider>
                </LocalizationProvider>
            </CssBaseline>
        </ThemeProvider>
    );
}

export default App;
